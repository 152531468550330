

document.addEventListener("DOMContentLoaded", function(event) {
  const search_input = document.body.querySelector("header form.search input");
  search_input.addEventListener("focus", (evt) => {
    search_input.parentNode.classList.add("focused");
  });
  search_input.addEventListener("blur", (evt) => {
    search_input.parentNode.classList.remove("focused");
  });



  const search_submit = document.body.querySelector("header form.search input[type=submit]");
  search_submit.addEventListener("click", (e) => {

    if (search_input.value.length > 0 && !search_input.parentNode.classList.contains("focused")) {
      e.preventDefault();
      search_input.focus();
    }
  });
});
